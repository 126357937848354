import StartGroupService from "./StartGroupService";
//import SporteventService from "./SporteventService";
import ScoringStartgroup from "@/models/sportevent/ScoringStartgroup";
import Discipline from "@/models/sporteventCalculation/Discipline";
import Starter from "@/models/sportevent/Starter";
import StartGroup from "@/models/sportevent/StartGroup";
import DisciplineCategory from "@/models/sporteventCalculation/DisciplineCategory";
import SporteventCompetitionService from "./SporteventCompetitionService";
import SporteventCompetition from "@/models/sportevent/SporteventCompetition";

class ScoringStartgroupService {
  async getAllBySportevent() {
    let startGroups = await StartGroupService.getAllBySportevent(true);
    let allSporteventCompetitions: SporteventCompetition[] = [];
    const scoringStartgroups: ScoringStartgroup[] = [];

    if(startGroups[0].sportevent.id != undefined){
      let sc = await SporteventCompetitionService.getBySportevent(startGroups[0].sportevent.id);
      if(sc != undefined){
        allSporteventCompetitions = sc;
      }
    }

    for (let i = 0; i < startGroups.length; i++) {
      const scoringStartgroup = new ScoringStartgroup();
      const startGroupId = startGroups[i].id;
      if (startGroupId !== undefined) {
        scoringStartgroup.startGroupId = startGroupId;
      }
      scoringStartgroup.startGroupName = new StartGroup(
        startGroups[i]
      ).StartGroupName;
            
      let disciplineCategories: DisciplineCategory[] = [];
      startGroups[i].starters.forEach( (s: Starter) => {
        scoringStartgroup.startersString += `${s.person.firstname} ${s.person.lastname}, `;

        try{
          let index = allSporteventCompetitions.findIndex( (sc:SporteventCompetition) => sc.competition.id === s.sporteventCompetition.competition.id);
          if(index != undefined && index > -1 && allSporteventCompetitions[index].disciplines.length > 0 ){
            allSporteventCompetitions[index].disciplines.forEach( (d: Discipline) => {
              let dIndex = disciplineCategories.findIndex( dc => dc.id === d.disciplineCategory.id);
              if(dIndex === -1){
                disciplineCategories.push(new DisciplineCategory(d.disciplineCategory));
              }
            }); 
          }
        } catch{ }
      });
      

      /*
      for (let x = 0; x < startGroups[i].sporteventCompetitions.length; x++) {
        disciplineCategories.push(
          ...(allSporteventCompetitions.filter( sc => sc.id === startGroups[i].sporteventCompetitions[x].id)[0]).disciplines.map(
            (d: Discipline) => new DisciplineCategory(d.disciplineCategory)
          )
        );
      }
      
      // Removing Duplicate
      disciplineCategories = disciplineCategories.filter((obj, pos, arr) => {
        return (
          arr
            .map(
              (disciplineCategory: DisciplineCategory) =>
                disciplineCategory["id"]
            )
            .indexOf(obj["id"]) === pos
        );
      });
      */
      scoringStartgroup.disciplineCategories = disciplineCategories;
      scoringStartgroups.push(scoringStartgroup);
    }
    scoringStartgroups.sort((a, b) =>
      a.startGroupName > b.startGroupName ? 1 : -1
    );

    return scoringStartgroups;
  }
}

export default new ScoringStartgroupService();
