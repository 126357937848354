import DisciplineCategory from "../sporteventCalculation/DisciplineCategory";

export default class ScoringStartgroup {
  // Due to the performance in the search, only the strings and not the objects were intentionally created
  startGroupName = "";
  startGroupId = "";
  disciplineCategories: DisciplineCategory[] = [];
  startersString = "";

  constructor(scoringStartgroup?: ScoringStartgroup) {
    if (scoringStartgroup === undefined || scoringStartgroup === null) {
      return;
    }
    this.startGroupName = scoringStartgroup.startGroupName;
    this.startGroupId = scoringStartgroup.startGroupId;
    this.disciplineCategories = scoringStartgroup.disciplineCategories;
    this.startersString = scoringStartgroup.startersString;
  }
}
